@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 100 900;
    font-display: optional;
    src: url(../fonts/ibm-plex-sans-var.woff2) format("woff2");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 100 900;
    font-display: optional;
    src: url(../fonts/ibm-plex-sans-var-italic.woff2) format("woff2");
  }

  :root {
    --primary: #007aff;
    --primary-light: #298fff;
    --primary-dark: #0066d6;
    --page: #fafafa;
    --code: #262626;
    --text-code: #f5f5f5;
    --page-inverted: #171717;
    --text: #262626;
    --text-muted: #525252;
    --text-inverted: #fafafa;
    --divider: #d4d4d4;
    --nav-bg: #f5f5f5;
  }

  .dark {
    --primary: #ec4899;
    --primary-light: #fb7185;
    --primary-dark: #db2777;
    --page: #171717;
    --code: #262626;
    --text-code: #fafafa;
    --page-inverted: #fafafa;
    --text: #e5e7eb;
    --text-muted: #a3a3a3;
    --divider: #404040;
    --nav-bg: #262626;
  }

  .highlight code .k {
    color: #d55fde;
  }
  .highlight code .c,
  .highlight code .err,
  .highlight code .l,
  .highlight code .g,
  .highlight code .o,
  .highlight code .x,
  .highlight code .p,
  .highlight code .ch,
  .highlight code .cm,
  .highlight code .cpf,
  .highlight code .c1,
  .highlight code .cs,
  .highlight code .nn,
  .highlight code .ni,
  .highlight code .ne,
  .highlight code .ld,
  .highlight code .nx,
  .highlight code .py,
  .highlight code .gt,
  .highlight code .gp,
  .highlight code .w,
  .highlight code .gs,
  .highlight code .gr {
    color: #aab1c0;
  }
  .highlight code .ge {
    color: #aab1c0;
    text-decoration: underline;
  }
  .highlight code .go {
    color: #44475a;
  }
  .highlight code .gh,
  .highlight code .gu {
    color: #aab1c0;
    font-weight: bold;
  }

  .highlight code .kd {
    color: #8be9fd;
    font-style: italic;
  }
  .highlight code .kc,
  .highlight code .cp,
  .highlight code .kn,
  .highlight code .kp,
  .highlight code .kr,
  .highlight code .kt {
    color: #d55fde;
  }
  .highlight code .m {
    color: #d8985f;
  }
  .highlight code .nb {
    color: #f5c876;
    font-style: italic;
  }
  .highlight code .sa,
  .highlight code .sb,
  .highlight code .sc,
  .highlight code .dl,
  .highlight code .sd,
  .highlight code .hll,
  .highlight code .no,
  .highlight code .s {
    color: #f5c876;
  }
  .highlight code .nf {
    color: #52adf2;
  }
  .highlight code .nl {
    color: #aab1c0;
    font-style: italic;
  }
  .highlight code .n,
  .highlight code .gd,
  .highlight code .nt {
    color: #ef596f;
  }
  .highlight code .mb,
  .highlight code .ow .highlight code .mf,
  .highlight code .si .highlight code .il .highlight code .mh,
  .highlight code .mi,
  .highlight code .mo {
    color: #d55fde;
  }
  .highlight code .nc,
  .highlight code .gi,
  .highlight code .s2,
  .highlight code .sh,
  .highlight code .fm,
  .highlight code .sx {
    color: #98c379;
  }
  .highlight code .se,
  .highlight code .sr,
  .highlight code .nd,
  .highlight code .na,
  .highlight code .s1,
  .highlight code .ss {
    color: #33d8e4;
  }
  .highlight code .bp {
    color: #aab1c0;
    font-style: italic;
  }
  .highlight code .nv,
  .highlight code .vc,
  .highlight code .vg,
  .highlight code .vi,
  .highlight code .vm {
    color: #8be9fd;
    font-style: italic;
  }
}

@layer utilities {
  [x-cloak] {
    @apply hidden;
  }
}
